<template>
  <div>
    <v-dialog v-model="dialog_generate" scrollable persistent width="650">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326"
        >
          <span class="white--text"> Generate Voucher </span>
          <v-spacer></v-spacer>
          <v-icon
            @click="dialog_generate = false"
            color="white"
            :disabled="process.run"
            >mdi-close-circle</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                label="Please Select Company"
                outlined
                dense
                flat
                color="primary"
                hide-details="auto"
                no-data-text="no data found"
                :loading="process.run"
                v-model="form.company_id"
                item-value="id"
                item-text="name"
                :items="company.list"
                :search-input.sync="search_company"
                @keyup="searchCompany"
                @change="
                  fetchProgram();
                  fetchMerchant();
                "
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                label="Please Select Program"
                outlined
                dense
                flat
                color="primary"
                hide-details="auto"
                no-data-text="please select company first"
                :loading="process.run"
                v-model="form.program_id"
                item-value="id"
                item-text="title"
                :items="program.list"
                :search-input.sync="search_program"
                @keyup="searchProgram"
              >
                <template v-slot:selection="data">
                  <div class="text-capitalize">{{ data.item.title }}</div>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize"
                      v-html="data.item.title"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                flat
                hide-details="auto"
                v-model="form.order_id"
                label="Input Order Name"
                item-value="id"
                item-text="id"
                color="primary"
              >
              </v-text-field>
              <div class="pt-1 text-body-2 red--text" v-if="show_format_order">
                {{ format_order_text }}
              </div>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                label="Please Select Merchant"
                outlined
                dense
                flat
                color="primary"
                hide-details="auto"
                no-data-text="please select company first"
                :loading="process.run"
                v-model="form.merchant_id"
                :items="merchant.list"
                item-value="id"
                item-text="name"
                :search-input.sync="search_merchant"
                @keyup="searchMerchant"
              >
                <template v-slot:selection="data">
                  <div class="text-capitalize">{{ data.item.name }}</div>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize"
                      v-html="data.item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <VueCtkDateTimePicker
                label="Please Select Start Date"
                color="#f05326"
                button-color="#f05326"
                locale="id"
                no-button-now
                position="bottom"
                format="YYYY-MM-DD HH:mm:ss"
                v-model="form.start_date"
              >
              </VueCtkDateTimePicker>
            </v-col>
            <v-col cols="12">
              <VueCtkDateTimePicker
                label="Please Select End Date"
                color="#f05326"
                button-color="#f05326"
                locale="id"
                no-button-now
                position="bottom"
                format="YYYY-MM-DD HH:mm:ss"
                :min-date="form.start_date"
                v-model="form.end_date"
              >
              </VueCtkDateTimePicker>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                flat
                hide-details="auto"
                v-model="form.duration_in_days"
                label="Durasi Voucher (Days)"
                color="primary"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                flat
                hide-details="auto"
                v-model="form.total"
                label="Total Voucher"
                color="primary"
              >
              </v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-checkbox
                v-model="form.with_pmo_code"
                class="mt-0"
                label="With PMO Code"
              >
              </v-checkbox>
            </v-col> -->
            <!-- <v-col cols="12" md="6">
              <v-checkbox
                v-model="form.is_sold"
                class="mt-0"
                label="Is Sold"
              >
              </v-checkbox>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#EF6C00"
            class="text-capitalize"
            :loading="process.run"
            @click="
              form = {};
              show_format_order = false;
            "
          >
            Reset
          </v-btn>
          <v-btn
            depressed
            color="#EF6C00"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="generateLicense()"
          >
            Generate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import { get, post } from "@/service/Axios";
import Snackbar from "@/components/Snackbar.vue";

export default {
  components: {
    Snackbar,
  },
  data() {
    return {
      dialog_generate: false,
      show_format_order: false,
      format_order_text: "",
      date_start: null,
      date_end: null,
      process: {
        run: false,
      },
      limit: 10,
      search_company: null,
      search_program: null,
      search_merchant: null,
      form: {
				duration_in_days: null,
        with_pmo_code: false,
        is_sold: false,
      },
      company: {},
      program: {},
      merchant: {},
    };
  },
  watch: {
    "form.order_id": function (val) {
      this.$set(this.form, "order_id", (val || "").toUpperCase());
    },
    "form.duration_in_days": function (val) {
      const angka = parseInt(val);
      this.$nextTick(() => {
        this.$set(this.form, "duration_in_days", isNaN(angka) ? null : angka);
      });
    },
    "form.total": function (val) {
      const angka = parseInt(val);
      this.$nextTick(() => {
        this.$set(this.form, "total", isNaN(angka) ? null : angka);
      });
    },
  },
  methods: {
    dialogShow() {
      this.dialog_generate = true;
      this.fetchCompany();
    },
    async fetchCompany() {
      this.company = {};
      this.process.run = true;
      await get(`company/v1/superuser/list`, {
        params: {
          limit: this.limit,
          q: this.search_company,
        },
      }).then((response) => {
        let res = response.data;
        this.company = res.data;
        this.process.run = false;
      });
    },
    async fetchProgram() {
      if (this.form.company_id != null) {
        this.program = {};
        this.process.run = true;
        await get(
          `program/v1/superuser/list?company_id=${this.form.company_id}`,
          {
            params: {
              limit: this.limit,
              q: this.search_program,
            },
          }
        ).then((response) => {
          let res = response.data;
          this.program = res.data;
          this.process.run = false;
        });
      }
    },
    async fetchMerchant() {
      if (this.form.company_id != null) {
        this.process.run = true;
        await get(
          `merchant/v1/superuser/list?company_id=${this.form.company_id}`,
          {
            params: {
              limit: this.limit,
              q: this.search_merchant,
            },
          }
        ).then((response) => {
          let res = response.data;
          this.merchant = res.data;
          this.process.run = false;
        });
      }
    },
    generateLicense() {
      const white_space = /\s/g;
      const format = /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

      if (white_space.test(this.form.order_id)) {
        this.show_format_order = true;
        this.format_order_text = "Mengandung spasi yang tidak diperbolehkan";
      } else {
        if (format.test(this.form.order_id)) {
          this.show_format_order = true;
          this.format_order_text = "Mengandung simbol yang tidak diperbolehkan";
        } else {
          this.process.run = true;
          post(`license/v1/superuser/generate`, {
            data: this.form,
          })
            .then((response) => {
              let res = response.data;
              if (res.status) {
                this.$refs.snackbar.open(
                  "#4CAF50",
                  `Berhasil Generate Voucher`
                );
                this.dialog_generate = false;
                this.form = {};
                this.show_format_order = false;
                this.$router.push(`/order/license/${res.data.order_id}`);
              } else {
                this.$refs.snackbar.open("#D32F2F", `Gagal Generate Voucher`);
                this.show_format_order = false;
              }
              this.process.run = false;
            })
            .catch(() => {
              this.process.run = false;
              this.show_format_order = false;
            });
        }
      }
    },
    searchCompany() {
      if (this.search_company != null && this.search_company.length > 2) {
        this.fetchCompany();
      } else if (
        this.search_company != null &&
        this.search_company.length < 1
      ) {
        this.fetchCompany();
      }
    },
    searchProgram() {
      if (this.search_program != null && this.search_program.length > 2) {
        this.fetchProgram();
      } else if (
        this.search_program != null &&
        this.search_program.length < 1
      ) {
        this.fetchProgram();
      }
    },
    searchMerchant() {
      if (this.search_merchant != null && this.search_merchant.length > 2) {
        this.fetchMerchant();
      } else if (
        this.search_merchant != null &&
        this.search_merchant.length < 1
      ) {
        this.fetchMerchant();
      }
    },
  },
};
</script>
